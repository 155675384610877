import { useEffect, useState } from "react";
import "./App.css";

function App() {
  return (
    <div className="App">
      <div class="bg-white">
        <div class="relative isolate px-6 pt-14 lg:px-8">
          <div
            class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
            aria-hidden="true"
          >
            <div
              class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            ></div>
          </div>
          <div class="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
            <div class="text-center">
              <h1 class="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                JGT Consulting
              </h1>
              <p class="mt-6 text-lg leading-8 text-gray-600">Resource Site</p>
              <div class="mt-10 flex items-center justify-center gap-x-6 flex-col gap-11">
                <a
                  href="https://docs.google.com/presentation/d/1YP6U9zg7fJejWn9FJ-JFdxw2t7IC8AqrJaDkMw-DViE/edit?usp=sharing"
                  target="_blank"
                  class=" text-[3vw] rounded-md bg-indigo-600 px-6 py-7 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Slideshow
                </a>
                <a
                  href="https://jgt.jadenzhang.com"
                  target="_blank"
                  class=" text-[3vw] rounded-md bg-indigo-600 px-6 py-7 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Quiz
                </a>
                <a
                  href="https://docs.google.com/document/d/1FgLWWUUEZ0DP73InWzv6CYCNDcHOOLvs89Oz0rBjAa0/edit?usp=sharing"
                  target="_blank"
                  class=" text-[3vw] rounded-md bg-indigo-600 px-6 py-7 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Answer Key
                </a>
                <a
                  href="https://docs.google.com/document/d/1hjKgjF3UTgR0GJR_IhjLu7F7ayt0jWJ2kRfJsjzytRQ/edit?usp=sharing"
                  target="_blank"
                  class=" text-[3vw] rounded-md bg-indigo-600 px-6 py-7 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Handout
                </a>
                <a
                  href="https://docs.google.com/document/d/1alT8TjGEgn1KB14k3KDN5Hc9xewdPwx3scDg7Jm99Fw/edit?usp=sharing"
                  target="_blank"
                  class=" text-[3vw] rounded-md bg-indigo-600 px-6 py-7 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Citations
                </a>
              </div>
            </div>
          </div>
          <div
            class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
            aria-hidden="true"
          >
            <div
              class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            ></div>
          </div>
        </div>
      </div>
      <div>Made with ❤️ by Jaden Z</div>
    </div>
  );
}

export default App;
